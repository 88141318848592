import React from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { Link } from "react-router-dom";
// https://www.npmjs.com/package/@leenguyen/react-flip-clock-countdown

export default function Card({ project }) {

 return (
    <div>
      <figure className="relative flex flex-col items-center">
        <img src={`${process.env.REACT_APP_BASE_URL}/${project?.img}`} className="w-[300px] h-[200px] rounded-t-[24px] object-cover" alt="" />
        <div className="absolute top-[65%] w-fit rounded-md bg-slate-300 px-2 py-1 opacity-90">
          <FlipClockCountdown
            to={project?.deadline}
            labels={["DIAS", "HORAS", "MINUTOS", "SEGUNDOS"]}
            labelStyle={{
              fontSize: 10,
              fontWeight: 500,
              textTransform: "uppercase",
            }}
            digitBlockStyle={{
              width: 24,
              height: 36,
              fontSize: 20,
              color: "black",
              backgroundColor: "rgba(203, 213, 225, 0.95)",
            }}
            dividerStyle={{
              color: "red",
              height: 0,
            }}
            separatorStyle={{ color: "transparent", size: "0px" }}
            duration={1}
          >
            {/* component rendered when timer finishes */}
            <div className="flex items-center justify-center px-2 py-1">
              <h3 className="text-center">Licitación terminada</h3>
            </div>
          </FlipClockCountdown>
        </div>
      </figure>

      {/* rounded bottom might need configuration */}

      <div className="-mt-2 flex flex-col items-center rounded-b-[24px] bg-slate-300 text-zinc-600">
        <p className="mt-4 text-xs font-semibold">{project.text}</p>
        <h2 className="text-lg font-bold">{project.name}</h2>
        <Link
          // to="/cnscoop"
          to={`/system/licitation?id=${project?._id}`}
          className={`mb-10 mt-4 flex items-center justify-center gap-2 rounded-lg border-4 border-solid border-white   px-3 py-2 text-white bg-green-800 hover:bg-green-700`}
        >
          <img src={require("../../Assets/buttonIcon.png")} alt="" />
          <span className="font-russo text-lg">VER MAS</span>
        </Link>
      </div>
    </div>
  );
}
