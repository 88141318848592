import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";

const MatrizComparative = () => {
  const navigate = useNavigate()
  const [view, setView] = useState(2);
  const [excelUploaded,setExcelUploaded]=useState(null)

  let data = [
    {
      Nombre: "Bocadillos y Canapes",
      Licitación: "Canapés Gourmet",
      Cantidad: "500",
      Precio: "$5.4 MXN",
      Precio1: "$2,700 MXN",
      Sitio: "www.canapes.com.mx",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Cinco Catering",
      Licitación: "Canapés Gourmet",
      Cantidad: "480",
      Precio: "$9.2 MXN",
      Precio1: "$4,416 MXN",
      Sitio: "www.5catering.com.mx",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Appetit Catering & Store",
      Licitación: "Canapés Gourmet",
      Cantidad: "350",
      Precio: "$8.5 MXN",
      Precio1: "$2,975 MXN",
      Sitio: "www.appettit.mx",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Marlén Portillo Eventos",
      Licitación: "Canapés Gourmet",
      Cantidad: "420",
      Precio: "$7.8 MXN",
      Precio1: "$3,276 MXN",
      Sitio: "www.marlene.mx",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Grupo Dimento",
      Licitación: "Canapés Gourmet",
      Cantidad: "380",
      Precio: "6.3 MXN",
      Precio1: "$2,394 MXN",
      Sitio: "www.dimento.com.mx",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Alimentus",
      Licitación: "Canapés Gourmet",
      Cantidad: "440",
      Precio: "$12 MXN",
      Precio1: "$5,280 MXN",
      Sitio: "www.alimentus.com",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
  ];
  let data2 = [
    {
      Nombre: "Bocadillos y Canapes",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Garantías: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Soporte: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Fecha: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Lugar: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Cinco Catering",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Garantías: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Soporte: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Fecha: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Lugar: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Appetit Catering & Store",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Garantías: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Soporte: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Fecha: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Lugar: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Marlén Portillo Eventos",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Garantías: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Soporte: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Fecha: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Lugar: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Grupo Dimento",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Garantías: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Soporte: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Fecha: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Lugar: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
    {
      Nombre: "Alimentus",
      Especificaciones: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Garantías: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Soporte: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Fecha: "Lorem ipsum slañsa aospaops aopsaps sasa.",
      Lugar: "Lorem ipsum slañsa aospaops aopsaps sasa.",
    },
  ];
  const data3 = [
    {
      id: 1,
      avatar: require("../../Assets/Group 291.png"),
      name: "Bocadillos y Canapes",
      rating: 93,
    },
    {
      id: 2,
      avatar: require("../../Assets/Group 291.png"),
      name: "Cinco Catering",
      rating: 91,
    },
    {
      id: 3,
      avatar: require("../../Assets/Group 291.png"),
      name: "Appetit Catering & Store",
      rating: 89,
    },
    {
      id: 4,
      avatar: require("../../Assets/Group 291.png"),
      name: "Grupo Dimento",
      rating: 86,
    },
    {
      id: 5,
      avatar: require("../../Assets/Group 291.png"),
      name: "Marlén Portillo Eventos",
      rating: 85,
    },
    {
      id: 6,
      avatar: require("../../Assets/Group 291.png"),
      name: "Alimentus",
      rating: 82,
    },
  ];

  const data4 = [
    {
      id: 1,
      criterio: "Cumplimiento de Especificaciones",
      definicion:
        "Porcentaje de cumplimiento de lo solicitado por CSN (lo asigna el proveedor).",
      ponderacion: 40,
    },
    {
      id: 2,
      criterio: "Adaptación al presupuesto",
      definicion:
        "Que el costo sea menor que el presupuesto, asimismo, mide el que tan bajo es el costo con relación al presupuesto.",
      ponderacion: 30,
    },
    {
      id: 3,
      criterio: "Soporte",
      definicion: "Adaptación a los horarios de servicio de CSN.",
      ponderacion: 10,
    },
    {
      id: 4,
      criterio: "Fecha o plazos de entrega",
      definicion: "Plazos en los que entregará el servicio.",
      ponderacion: 20,
    },
  ];

  const totalPonderacion = data4.reduce((sum, row) => sum + row.ponderacion, 0);

  return (
    <>
      <div className="flex flex-col items-center bg-[url('./Assets/lowerBg.png')] bg-top bg-no-repeat py-36 lg:min-h-[1200px]">
        <div className="flex max-w-[1328px] flex-col px-4 lg:w-[90%]">
         <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <button onClick={() => navigate(-1)} className={`bg-[#025624] flex items-center justify-center rounded-lg gap-x-3 text-white py-2 px-6`}>
              <FaArrowLeft />
              ATRÁS
            </button>
            <button className={`${excelUploaded ? "bg-green-600":"bg-[#025624]"} btnBorder ml-auto flex py-2 px-6 items-center justify-center gap-2 self-end rounded-lg roboto-slab text-[10px] leading-[12px] text-white lg:ml-0 lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]`}>
              <FiDownload className="text-lg lg:text-2xl" />
              AGREGAR TABLA
            </button>
          </div>
          <button className="btnBorder mb-4 ml-auto flex py-2 w-28 items-center justify-center gap-2 self-end rounded-lg bg-[#025624] hover:bg-green-600 roboto-slab text-[10px] leading-[12px] text-white lg:ml-0 lg:h-12 lg:w-36 lg:text-[14px] lg:leading-[17px]">
            <FiDownload className="text-lg lg:text-2xl" />
            DESCARGAR
          </button>
         </div>

          <div className="flex flex-col items-center">
            <div className="flex items-center gap-[15px] lg:gap-[40px]">
              <img
                src={require("../../Assets/triangle.png")}
                className="h-10 w-10 lg:h-16 lg:w-16"
                alt=""
              />
              <div className="text-[25px] font-bold font-roboto leading-[30px] text-[#71A850] lg:text-[36px] lg:leading-[47px]">
                {view === 1 && "Tabla de Resultados"}
                {view === 2 && "Matriz Comparativa"}
                {view === 3 && "Matriz De Ponderaciones"}
              </div>
            </div>
          </div>

          <div className="mt-12 flex w-full flex-wrap items-center justify-around gap-2 sm:flex-nowrap">
            <button
              onClick={() => setView(1)}
              className={`${view === 1 ? "bg-[#71A850]" : "bg-[#585857]"} btnBorder flex items-center justify-center gap-px rounded-lg px-1 py-3 text-xs leading-[18px] text-white sm:gap-2 sm:text-base md:px-4 lg:text-xl lg:leading-[24px]`}
            >
              <img
                src={require("../../Assets/btn1Image.png")}
                className="w-6 lg:w-10"
                alt=""
              />
              <span className="font-russo">TABLA DE RESULTADOS</span>
            </button>

            <button
              onClick={() => setView(2)}
              className={`${view === 2 ? "bg-[#71A850]" : "bg-[#585857]"} btnBorder flex items-center justify-center gap-px rounded-lg px-1 py-3 text-xs leading-[18px] text-white sm:gap-2 sm:text-base md:px-4 lg:text-xl lg:leading-[24px]`}
            >
              <img
                src={require("../../Assets/btn2Image.png")}
                className="w-6 lg:w-10"
                alt=""
              />
              <div className="roboto-slab">MATRIZ COMPARATIVA</div>
            </button>
            <button
              onClick={() => setView(3)}
              className={`${view === 3 ? "bg-[#71A850]" : "bg-[#585857]"} btnBorder flex items-center justify-center gap-px rounded-lg px-1 py-3 text-xs leading-[18px] text-white sm:gap-2 sm:text-base md:px-4 lg:text-xl lg:leading-[24px]`}
            >
              <img
                src={require("../../Assets/btn3Image.png")}
                className="w-4 sm:w-6 lg:w-10"
                alt=""
              />
              <div className="roboto-slab">MATRIZ DE PONDERACIONES</div>
            </button>
          </div>

          {view === 1 && (
            <div className="mx-auto mt-10 w-full max-w-[1000px] overflow-x-auto bg-white text-center text-xs sm:text-base">
              <table className="min-w-full rounded-lg border border-gray-300 shadow-lg">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                      #
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                      Avatar
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                      Nombre de Proveedor
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-900 sm:px-4">
                      Calificación Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data3.map((provider) => (
                    <tr key={provider.id}>
                      <td className="border border-gray-600 py-1 sm:px-2">
                        {provider.id}
                      </td>
                      <td className="border border-gray-600 py-1 sm:px-2">
                        <img
                          src={provider.avatar}
                          alt={provider.name}
                          className="mx-auto h-12 w-12 rounded-full"
                        />
                      </td>
                      <td className="border-black-600 border py-1 sm:px-2">
                        {provider.name}
                      </td>
                      <td className="border border-gray-300 py-1 sm:px-2">
                        {provider.rating}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {view === 3 && (
            <div className="mx-auto mt-10 max-w-[1000px] overflow-x-auto bg-white text-center text-xs sm:text-base">
              <table className="min-w-full rounded-md border border-gray-300 shadow-md">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-700 sm:px-4">
                      #
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-700 sm:px-4">
                      Criterios
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-700 sm:px-4">
                      Definición de Criterios
                    </th>
                    <th className="border border-gray-300 px-1 py-3 font-semibold text-gray-700 sm:px-4">
                      Ponderación
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data4.map((row) => (
                    <tr key={row.id}>
                      <td className="border border-gray-300 py-2 sm:px-4">
                        {row.id}
                      </td>
                      <td className="border border-gray-300 py-2 sm:px-4">
                        {row.criterio}
                      </td>
                      <td className="border border-gray-300 py-2 sm:px-4">
                        {row.definicion}
                      </td>
                      <td className="border border-gray-300 py-2 sm:px-4">
                        {row.ponderacion}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      className="border border-gray-300 px-1 py-2 sm:px-4"
                      colSpan={2}
                    ></td>
                    <td className="border border-gray-300 px-1 py-2 font-semibold sm:px-4">
                      TOTAL
                    </td>
                    <td className="border border-gray-300 px-1 py-2 font-semibold sm:px-4">
                      100
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}

          {view === 2 && (
            <div className="tableouter mt-[30px] flex flex-col">
              <div className="flex items-center gap-3 mb-4">
                <img
                  src={require("../../Assets/triangle.png")}
                  className="h-6 w-6 lg:h-8 lg:w-8"
                  alt=""
                />
                <p className="text-2xl font-semibold text-[#585857]">Matriz General</p>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Nombre de Proveedor</TableCell>
                      <TableCell>Licitación</TableCell>
                      <TableCell>Cantidad</TableCell>
                      <TableCell>Precio Unitario</TableCell>
                      <TableCell>Precio Total</TableCell>
                      <TableCell>Sitio web</TableCell>
                      <TableCell>Especificaciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell> {/* Numbering */}
                        <TableCell>{item.Nombre}</TableCell>
                        <TableCell>{item.Licitación}</TableCell>
                        <TableCell>{item.Cantidad}</TableCell>
                        <TableCell>{item.Precio}</TableCell>
                        <TableCell>{item.Precio1}</TableCell>
                        <TableCell>
                          <a
                            href={`https://${item.Sitio}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.Sitio}
                          </a>
                        </TableCell>
                        <TableCell>{item.Especificaciones}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="flex items-center gap-3 mb-4">
                <img
                  src={require("../../Assets/triangle.png")}
                  className="h-6 w-6 lg:h-8 lg:w-8"
                  alt=""
                />
                <p className="text-2xl font-semibold text-[#585857]">Matriz General</p>
              </div>
              <TableContainer component={Paper} className="mt-[40px]">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Nombre de Proveedor</TableCell>
                      <TableCell>Especificaciones Especiales </TableCell>
                      <TableCell>Garantías</TableCell>
                      <TableCell>Soporte</TableCell>
                      <TableCell>Fecha de entrega</TableCell>
                      <TableCell>Lugar de entrega</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data2.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell> {/* Numbering */}
                        <TableCell>{item.Nombre}</TableCell>
                        <TableCell>{item.Especificaciones}</TableCell>
                        <TableCell>{item.Garantías}</TableCell>
                        <TableCell>{item.Soporte}</TableCell>
                        <TableCell>{item.Fecha}</TableCell>
                        <TableCell>{item.Lugar}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MatrizComparative;
