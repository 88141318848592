import { useState } from "react"
import { FaRegEyeSlash,FaRegEye } from "react-icons/fa";
import styles from "./AddProjects.module.css"
import RegIcn from "../../Images/RegIcn.png";
import ProRegStep from "../../Images/proRegStep.png";
import HideIcn from "../../Images/hide2.png";
import FileUpIcn from "../../Images/fileupIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
function AddProjects() {
    const navigate = useNavigate();
    const [fileErrors, setFileErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState({});

 const [activeTab,setActiveTab]= useState(3)

const tabs=[
  {name:"Creación de Usuarios",img:require("../../Assets/tab1.png")},
  {name:"Administración de usuarios",img:require("../../Assets/tab2.png")},
  {name:"Administración de Proveedores",img:require("../../Assets/tab3.png")},
  {name:"Añadir  Proyectos",img:require("../../Assets/tab1.png")},
  {name:"Envío de Correos",img:require("../../Assets/tab4.png")},
  {name:"Auditoría de Uso",img:require("../../Assets/tab5.png")},
  {name:"Reportes",img:require("../../Assets/tab6.png")},
]




const [formDataNew, setFormData] = useState({
  projectArea:"",
  voter:"",
  projectTitle: "",
  requestType: "",
  description: "",
  street: "",
  numExt: "",
  characteristics: "",
  specialSpecifications: "",
  deliveryDate: { day: "", month: "", year: "" },
  openingDate: { day: "", month: "", year: "" },
  proposalDeadline: { day: "", month: "", year: "" },
  decisionDate: { day: "", month: "", year: "" },
  budget: "",
  password: "",
  files: [
    "Imagen formato jpeg formato web 1350 px X1350",
    "Imagen formato jpeg formato web 1350 px X1350",
    "Imagen formato jpeg formato web 1350 px X1350",
  ],
});
  // Helper functions for handling dates
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  const currentYear = new Date().getFullYear();
  const startYear = 2024;
  const endYear = 2034;
  const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

  const monthToNumber = (month) => {
    const months = {
      Enero: '01', Febrero: '02', Marzo: '03', Abril: '04', Mayo: '05', Junio: '06',
      Julio: '07', Agosto: '08', Septiembre: '09', Octubre: '10', Noviembre: '11', Diciembre: '12',
    };
    return months[month] || '01';
  };

  const formatDate = (dateObj) => {
    const { year, month, day } = dateObj;
    if (!year || !month || !day) {
      console.error(`Incomplete date: year=${year}, month=${month}, day=${day}`);
      return null; // Return null if date parts are missing
    }
    const formattedMonth = monthToNumber(month);
    const formattedDay = String(day).padStart(2, '0');
    const dateStr = `${year}-${formattedMonth}-${formattedDay}`;
    return new Date(dateStr);
  };



const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value
  }));
};
const handleDateChange = (dateType, part) => (e) => {
  const value = e.target.value;
  setFormData((prevData) => {
    const updatedFormData = {
      ...prevData,
      [dateType]: {
        ...prevData[dateType],
        [part]: value,
      },
    };

    // Perform date validation
    const errors = { ...errorMessages };
    const openingDate = formatDate(updatedFormData.openingDate);
    const proposalDeadline = formatDate(updatedFormData.proposalDeadline);
    const closingDate = formatDate(updatedFormData.decisionDate);
    const deliveryDate = formatDate(updatedFormData.deliveryDate);

    // Check for missing dates
    if (!openingDate) {
      errors.openingDate = "La fecha de apertura es obligatoria.";
    } else {
      delete errors.openingDate;
    }

    if (!proposalDeadline) {
      errors.proposalDeadline = "La fecha límite de entrega es obligatoria.";
    } else {
      delete errors.proposalDeadline;
    }

    if (!closingDate) {
      errors.closingDate = "La fecha de cierre es obligatoria.";
    } else {
      delete errors.closingDate;
    }

    if (!deliveryDate) {
      errors.deliveryDate = "La fecha de entrega es obligatoria.";
    } else {
      delete errors.deliveryDate;
    }

    // Perform date comparisons for valid dates
    if (proposalDeadline && closingDate && proposalDeadline >= closingDate) {
      errors.proposalDeadline =
        "Favor de corregir la fecha límite de entrega ya que debe de ser anterior a la fecha de cierre.";
    } else {
      delete errors.proposalDeadline;
    }

    if (openingDate && (proposalDeadline <= openingDate || closingDate <= openingDate || deliveryDate <= openingDate)) {
      errors.openingDate = "Esta fecha no puede ser anterior a la Fecha de apertura. Favor de corregir.";
    } else {
      delete errors.openingDate;
    }

    if (deliveryDate && (proposalDeadline >= deliveryDate || closingDate >= deliveryDate)) {
      errors.deliveryDate = "La Fecha de entrega del bien o servicio deberá de ser posterior a las anteriores. Favor de corregir.";
    } else {
      delete errors.deliveryDate;
    }

    setErrorMessages(errors);
    return updatedFormData;
  });
};


const handleFileChange = (index) => (event) => {
  const file = event.target.files[0];

  setFileErrors((prevErrors) => {
    const newErrors = [...prevErrors];
    if (!file) {
      newErrors[index] = "Debe seleccionar un archivo válido.";
    } else {
      const maxSizeInKB = 120;
      if (file.size > maxSizeInKB * 1024) {
        newErrors[index] = `El archivo supera el tamaño máximo permitido de ${maxSizeInKB} KB.`;
      } else {
        newErrors[index] = ""; // Clear error if file is valid
      }
    }
    return newErrors;
  });

  if (file) {
    setFormData((prevData) => {
      const newFiles = [...prevData.files];
      newFiles[index] = file.name;
      return { ...prevData, files: newFiles };
    });
  }
};

const handleSubmit = async () => {
  const newErrors = {};

  // Validate required fields
  const requiredFields = [
    "projectArea",
    "voter",
    "projectTitle",
    "requestType",
    "description",
    "street",
    "numExt",
    "characteristics",
    "specialSpecifications",
    "password",
  ];
  requiredFields.forEach((field) => {
    if (!formDataNew[field]) {
      newErrors[field] =
        "El campo superior es obligatorio y debe ser llenado para poder proceder.";
    }
  });

  // Check for missing or invalid files
  formDataNew.files.forEach((fileName, index) => {
    if (!fileName || fileErrors[index]) {
      newErrors[`file${index}`] = "Debe seleccionar un archivo válido.";
    }
  });

  // Combine with existing date errors
  const errors = { ...errorMessages, ...newErrors };
  if (Object.keys(errors).length > 0) {
    setErrorMessages(errors);
    return;
  }

  const formData = new FormData();
  formData.append("area", formDataNew.projectArea);
  formData.append("vote", formDataNew.voter);
  formData.append("title", formDataNew.projectTitle);
  formData.append("typeOfRequirement", formDataNew.requestType);
  formData.append("description", formDataNew.description);
  formData.append("calle", formDataNew.street);
  formData.append("numExt", formDataNew.numExt);
  formData.append("features", formDataNew.characteristics);
  formData.append("specialSpecifications", formDataNew.specialSpecifications);

  formData.append("dateOfDelivery", formatDate(formDataNew.deliveryDate) || "");
  formData.append("openingDate", formatDate(formDataNew.openingDate) || "");
  formData.append("deadline", formatDate(formDataNew.proposalDeadline) || "");
  formData.append("closingDate", formatDate(formDataNew.decisionDate) || "");

  formData.append("password", formDataNew.password);

  formDataNew.files.forEach((fileName, index) => {
    const fileInput = document.getElementById(`fileUpload${index + 1}`);
    if (fileInput && fileInput.files.length > 0) {
      formData.append("files", fileInput.files[0]); 
    }
  });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/project/add-project`,
      formData,
      {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    );

    alert(" El proyecto ha sido añadido éxitosamente.  ");
    console.log(response);
    navigate("/system/proyectos");
  } catch (error) {
    if (error.response) {
      console.error("Error data:", error.response.data);
      alert(`Signup failed: ${error.response.data.message || error.message}`);
    } else {
      console.error("Error:", error);
      alert("Signup failed!");
    }
  }
};


  return (
    <div className="block   md:grid-cols-[1fr_2fr] lg:grid grid-cols-[1fr_3fr] xl:grid-cols-[1fr_4fr]">
      
      <ul className="h-full min-h-screen border-t border-solid border-green-600 bg-[#71A850]">
        {tabs.map((tab,i)=><li className={`${activeTab===i?"bg-[#025624]":"bg-[#71A850]"} flex flex-shrink-0 items-center gap-4 cursor-pointer px-4 py-4 border-b border-solid border-green-600`} onClick={()=>setActiveTab(i)}>
          <img src={tab.img} alt="" className="w-[43.4px]" />
          <p className="text-white font-semibold  text-lg">{tab.name}</p>
        </li>)}
      </ul>

      <div className="bg-[url('./Assets/proyectos-fondo.jpg')] bg-cover bg-no-repeat flex flex-col items-center">
        <div className="flex items-center mt-5 sm:mt-36 gap-4 lg:gap-10">
          <img src={require("../../Assets/triangleDark.png")} className="h-10 w-10 lg:h-16 lg:w-16" alt=""/>
          <div className="text-2xl font-bold font-roboto leading-[30px] text-[#025624] lg:text-[36px] lg:leading-[47px]">
            {tabs[activeTab].name}
          </div>
        </div>

        {activeTab===3 && <div className={styles.mainProjectFields}>
            <div className={styles.projectRegisterMain}>
      <div className={styles.projectRegisterContainer}>
        <div className={styles.projectRegisterContainer2}>
          <div className={styles.registerInputMain}>
          Área del Proyecto:*
          <select name="projectArea" className={styles.inputRegister} onChange={handleInputChange} >
                <option value=""></option>
                <option value="">Proyectos de Construcción</option>
        <option value="">Proyectos Generales</option>
        <option value="">Proyectos de Tecnología e Innovación</option>
            </select>

          </div>
          {errorMessages.projectArea && <div className={styles.errorText}>{errorMessages.projectArea}</div>}

          <div className={styles.registerInputMain}>
          Votante:*
            <select name="voter" className={styles.inputRegister} onChange={handleInputChange} >
                <option value=""></option>
                <option value="">Option1</option>
            </select>

          </div>
          {errorMessages.voter && <div className={styles.errorText}>{errorMessages.voter}</div>}

          <div className={styles.registerInputMain}>
            Título del Proyecto:*
            <input type="text" name="projectTitle" className={styles.inputRegister} onChange={handleInputChange} />

          </div>
          {errorMessages.projectTitle && <div className={styles.errorText}>{errorMessages.projectTitle}</div>}

          <div className={styles.radioMaindiv}>
            <div className={styles.radioText}>Tipo de requerimiento:*</div>
            {["Bienes", "Servicios", "Ambos"].map((type) => (
              <div key={type} className={styles.radioTextBtn}>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="requestType"
                    value={type}
                    onChange={handleInputChange}
                  />
                  <span className="checkmark"></span>
                </label>
                <div className={styles.radioText}>{type}</div>
              </div>
            ))}
          </div>
          {errorMessages.requestType && <div className={styles.errorText}>{errorMessages.requestType}</div>}

          <div className={styles.regDocmainText}>Descripción*</div>
          <textarea name="description" className={styles.registerTextarea} onChange={handleInputChange}></textarea>
          {errorMessages.description && <div className={styles.errorText}>{errorMessages.description}</div>}

          <div className={styles.registerInputMain2}>
            <div className={styles.inputRegisterLeft}>
            Cantidad:*
              <input type="text" name="street" className={styles.inputRegister2} onChange={handleInputChange} />
          {errorMessages.street && <div className={styles.errorText}>{errorMessages.street}</div>}

            </div>
            <div className={styles.inputRegisterRight}>
            Unidad:*
              <input type="text" name="numExt" className={styles.inputRegister2} onChange={handleInputChange} />
          {errorMessages.numExt && <div className={styles.errorText}>{errorMessages.numExt}</div>}

            </div>
          </div>
          <div className={styles.regDocmainText}>Características:*</div>
          <textarea name="characteristics" className={styles.registerTextarea} onChange={handleInputChange}></textarea>
          {errorMessages.characteristics && <div className={styles.errorText}>{errorMessages.characteristics}</div>}

          <div className={styles.regDocmainText}>Especificaciones Especiales:*</div>
          <textarea name="specialSpecifications" className={styles.registerTextarea2} onChange={handleInputChange}></textarea>
          {errorMessages.specialSpecifications && <div className={styles.errorText}>{errorMessages.specialSpecifications}</div>}

          {[{ label: "Fecha de apertura:*", dateType: "openingDate" },
        { label: "Fecha límite para entregar propuestas:*", dateType: "proposalDeadline" },
        { label: "Fecha de cierre (Toma de decisión):*", dateType: "decisionDate" },
        { label: "Fecha de entrega del bien o servicio:*", dateType: "deliveryDate" }]
        .map(({ label, dateType }) => (
          <div key={dateType} className={styles.selecterCont}>
            <div className={styles.regDocmainText}>{label}</div>
            <div className={styles.selecterMain}>
              <label>
                <select value={formDataNew[dateType].day} onChange={handleDateChange(dateType, 'day')} className={styles.selecterInput}>
                  <option value="" disabled>Día:</option>
                  {days.map((d) => (
                    <option key={d} value={d}>{d}</option>
                  ))}
                </select>
              </label>
              <label>
                <select value={formDataNew[dateType].month} onChange={handleDateChange(dateType, 'month')} className={styles.selecterInput2}>
                  <option value="" disabled>Mes:</option>
                  {months.map((m, index) => (
                    <option key={index} value={m}>{m}</option>
                  ))}
                </select>
              </label>
              <label>
                <select value={formDataNew[dateType].year} onChange={handleDateChange(dateType, 'year')} className={styles.selecterInput2}>
                  <option value="" disabled className={styles.selecterOptionText}>Año:</option>
                  {years.map((y) => (
                    <option key={y} value={y}>{y}</option>
                  ))}
                </select>
              </label>
            </div>
            {errorMessages[dateType] && <span style={{ color: 'red' }}>{errorMessages[dateType]}</span>}
          </div>
        ))}

          {/* <div className={styles.regDocmainText}>Presupuesto autorizado para el proyecto:*</div> */}
          <div className={styles.registerInputMain}>
          Presupuesto autorizado para el proyecto:*
            <div className={styles.showPasswordInput}>
              <input
                type="text"
                name="password"
                className={styles.passwordinput}
                onChange={handleInputChange}
              />
              <img
                src={HideIcn}
                alt=""
                className={styles.hideIcn}
                style={{ cursor: "pointer" }}
              />
            </div>
          {errorMessages.password && <div className={styles.errorText}>{errorMessages.password}</div>}
            
          </div>

          {formDataNew.files.map((fileName, index) => (
            <div key={index}>
              <div className={styles.regDocmainText}>Fotografía representativa {index + 1}. Máximo 120 kb.:*</div>
              <div className={styles.fileUploadContainer}>
              <input
  type="file"
  id={`fileUpload${index + 1}`}
  className={styles.regdocFileUp}
  onChange={handleFileChange(index)}
  accept=".jpeg, .jpg"
  style={{ display: "none" }}
/>
                <span className={styles.fileName}>{fileName}</span>
                <label htmlFor={`fileUpload${index + 1}`} className={styles.customFileUpload}>
                  <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                  ANEXAR
                </label>
              </div>
                 {/* Error message */}
    {fileErrors[index] && (
      <div className={styles.errorText}>{fileErrors[index]}</div>
    )}
            </div>
          ))}

          <div className={styles.RegBtnIcnMain}>
            <button className={styles.RegBtnIcn2} onClick={handleSubmit}>
              <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
              <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
              <span>GUARDAR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
{/* <div className={styles.rightMainSelector}>
    <select name="" id="" className={styles.selectright}> 
        <option value=""></option>
        <option value="">Proyectos de Construcción</option>
        <option value="">Proyectos Generales</option>
        <option value="">Proyectos de Tecnología e Innovación</option>
    </select>
</div> */}
        </div>}
     
      </div>
      
    </div>
  )
}

export default AddProjects
