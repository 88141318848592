import React from 'react'
import styles from "./ForgotPass.module.css"
import RegIcn from "../../Images/RegIcn.png";
import RegInpIcn1 from "../../Images/reginpIcn1.png";
import RegInpIcn2 from "../../Images/reginpIcn2.png";

const ForgotPass = () => {
  return (
    <div className={styles.registerMain}>
    <div className={styles.registerContainer}>
      <div className={styles.registerContainer2}>
      <div className={styles.registerLeftMain}>

      <div className={styles.registerMainHeading}>
              <img src={RegIcn} alt="" className={styles.regIcn} />
              ¿Olvidé Contraseña?
            </div>
        <div className={styles.registerInputMain}>
        Correo electrónico:*
        <input
          type="text"
          name="loginEmail"
          className={styles.inputRegister} 
        />
        
      </div>
      <div className={styles.RegBtnIcnMain}>
            <button
              className={styles.RegBtnIcn2}
            >
              <img src={RegInpIcn1} alt="" className={styles.reginpIcn1} />
              <img src={RegInpIcn2} alt="" className={styles.reginpIcn2} />
              <span>ENVIAR</span>
            </button>
          </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default ForgotPass
