import React, { useState } from "react";
import axios from "axios";
import styles from "./Register.module.css";
import RegIcn from "../../Images/RegIcn.png";
import HideIcn from "../../Images/hideIcn.png";
import BtnIcn from "../../Images/headBtnIcn1.png";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    loginEmail: "",
    loginPassword: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(true); // State to track email validation
  const [emailNotRegistered, setEmailNotRegistered] = useState(false); // State for unregistered email
  const [isLoginChecked, setIsLoginChecked] = useState(false); // State for login checkbox
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate email format
    if (name === "loginEmail") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsEmailValid(emailPattern.test(value));
      setEmailNotRegistered(false); // Reset error if user starts typing again
    }
  };

  const handleLoginCheckboxChange = () => {
    setIsLoginChecked((prev) => !prev);
  };

  // Handle login
  const handleLogin = async () => {
    const { loginEmail, loginPassword } = formData;

    if (!isLoginChecked) {
      alert("Please agree to the terms and conditions for login.");
      return;
    }
    if (!isEmailValid) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
        {
          email: loginEmail,
          password: loginPassword,
          role: "provider",
        }
      );
      localStorage.setItem("token", response.data?.token);
      alert("Login Successful!");
      navigate("/system/proyectos");
    } catch (error) {
      if (error.response?.status === 401) {
        setEmailNotRegistered(true); // Display error message
      } else {
        // alert("Login failed!");
        console.error(error);
      }
    }
  };

  return (
    <div>
      <div className={styles.registerMain}>
        <div className={styles.registerContainer}>
          <div className={styles.registerContainer2}>
            {/* Login Section */}
            <div className={styles.registerLeftMain}>
              <div className={styles.registerMainHeading}>
                <img src={RegIcn} alt="" className={styles.regIcn} />
                Ingreso de Sesión
              </div>
              <div className={styles.registerInputMain}>
                Correo electrónico:*
                <input
                  type="text"
                  name="loginEmail"
                  className={`${styles.inputRegister} ${
                    !isEmailValid || emailNotRegistered ? styles.invalidInput : ""
                  }`}
                  value={formData.loginEmail}
                  onChange={handleChange}
                />
                {!isEmailValid && (
                  <span className={styles.errorMessage}>
                    Favor de ingresar un correo electrónico válido.
                  </span>
                )}
                {emailNotRegistered && (
                  <span className={styles.errorMessage}>
                   El usuario no ha sigo registrado. Favor de registrarlo primero aquí:{" "}
                    <Link
                      to="/system/register-main"
                      className={styles.registerLink}
                    >
                      http://3.129.57.83/system/register-main
                    </Link>
                  </span>
                )}
              </div>
              <div className={styles.registerInputMain}>
                Contraseña:*
                <div className={styles.showPasswordInput}>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="loginPassword"
                    className={styles.passwordinput}
                    value={formData.loginPassword}
                    onChange={handleChange}
                  />
                  <img
                    src={HideIcn}
                    alt={showPassword ? "Hide password" : "Show password"}
                    className={styles.hideIcn}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <Link to="/system/forgotpassword">
                <div className={styles.forgoText}>¿Olvidé Contraseña?</div>
              </Link>
              <div className={styles.registerCheckbox}>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={isLoginChecked}
                    onChange={handleLoginCheckboxChange}
                  />
                  <span className="checkmark"></span>
                </label>
                Recuérdame
              </div>
              <div className={styles.RegBtnIcnMain}>
                <button
                  onClick={handleLogin}
                  className={styles.RegBtnIcn}
                  disabled={!isLoginChecked}
                >
                  <img src={BtnIcn} alt="" className={styles.navBtnIcn} />
                  ENTRAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
