import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom'; // Import useLocation
import styles from "./Header.module.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Icon1 from "../../Images/location-marker.png";
import Icon2 from "../../Images/email.png";
import Icon3 from "../../Images/telephone.png";
import Icon4 from "../../Images/world.png";
import Facebook from "../../Images/Facebook.png";
import LinkedIn from "../../Images/Linkedin.png";
import Instagram from "../../Images/Instagram.png";
import Youtube from "../../Images/youtube.png";
import Twitter from "../../Images/twitter.png";
import Logo from "../../Images/CSNlogo.png";
import Logo2 from "../../Images/logo2.png";
import BtnIcn from "../../Images/headBtnIcn1.png";
import BtnIcn2 from "../../Images/headBtnIcn2.png";
import "./Header.css";

const Header = () => {
  const location = useLocation(); // Get current location

  return (
    <div className={styles.headerMain}>
      <div className={styles.header1Main}>
        <div className={styles.header1Container}>
          <div className={styles.detailMain}>
            <img src={Icon2} alt="" className={styles.icon} />
            <div className={styles.detailText}>adquisiciones@csn.coop</div>
          </div>
          <div className={styles.detailMain}>
            <img src={Icon3} alt="" className={styles.icon} />
            <div className={styles.detailText}>818-305-6900</div>
          </div>
          <div className={styles.detailMain}>
            <img src={Icon4} alt="" className={styles.icon} />
            <div className={styles.detailText}>https://www.csn.coop/</div>
          </div>
          <div className={styles.detailMain}>
            <img src={Icon1} alt="" className={styles.icon} />
            <div className={styles.detailText}>
              Av. República Mexicana No.401 Col. Las Puentes 2 Sec. San Nicolás
              de los Garza N.L.
            </div>
          </div>
        </div>
        <div className={styles.header1Container2}>
          <a href='https://www.facebook.com/CSNCooperativaFinanciera' rel="noreferrer" target="_blank"><img src={Facebook} alt="" className={styles.logo} /></a>
          <a href='https://www.linkedin.com/company/csn-cooperativa-financiera/' rel="noreferrer" target="_blank"><img src={LinkedIn} alt="" className={styles.logo} /></a>
          <a href='https://www.instagram.com/csncooperativa/?hl=es-la' rel="noreferrer" target="_blank"><img src={Instagram} alt="" className={styles.logo} /></a>
          <a href='https://www.youtube.com/channel/UCUYIj1YXv6RZP0vNYeLkHQQ?view_as=subscriber' rel="noreferrer" target="_blank"><img src={Youtube} alt="" className={styles.logo} /></a>
          <a href='https://x.com/csncooperativa' rel="noreferrer" target="_blank"><img src={Twitter} alt="" className={styles.logo} /></a>
        </div>
      </div>
      <div>
        {["lg"].map((expand) => (
          <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
            <Container fluid>
              <Navbar.Brand href="#">
                <img src={Logo} alt="" className={styles.logoImg} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src={Logo2} alt="" className={styles.logoImg} />

                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-center flex-grow-1 pe-3 align-self-center">
                    <Link to="/system/" className={`nav-link ${location.pathname === '/system/' ? 'active-link' : ''}`}>
                      Inicio
                    </Link>

                    <Link to="/system/proyectos" className={`nav-link ${location.pathname === '/system/proyectos' ? 'active-link2' : ''}`}>
                      Proyectos
                    </Link>

                    <Link to="/system/cnscoop" className={`nav-link ${location.pathname === '/system/cnscoop' ? 'active-link3' : ''}`}>
                      Proveedores
                    </Link>

                    <Link to="/system/contact" className={`nav-link ${location.pathname === '/system/contact' ? 'active-link4' : ''}`}>
                      Contacto
                    </Link>
                  </Nav>
                  <div className={styles.NavButtonsMain}>
                    <Link to="/system/register-main" className={`${styles.navBtn2} ${location.pathname === '/system/register-main' ? 'active-link5' : ''}`}>
                      <img src={BtnIcn2} alt="" className={styles.navBtnIcn2} />
                      REGISTRO
                    </Link>
                    <Link to="/system/register" className={`${styles.navBtn} ${location.pathname === '/system/register' ? 'active-link5' : ''}`}>
                      <img src={BtnIcn} alt="ACCESO Icon" className={styles.navBtnIcn} />
                      ACCESO
                    </Link>

                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </div>
  );
};

export default Header;
