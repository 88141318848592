import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import Terms from "./Components/Terms/Terms";
import Register from "./Components/Register/Register";
import RegisterDoc from "./Components/RegisterDoc/RegisterDoc";
import Login from "./Components/Login/Login";
import SupRegister from "./Components/SupRegister/SupRegister";
import InternasContra from "./Components/InternasContra/InternasContra";
import Contact from "./Components/Contact/Contact";
import ContactInfo from "./Components/ContactInfo/ContactInfo";
import ProjectRegister from "./Components/ProjectRegister/ProjectRegister";
import RegisterGou from "./Components/RegisterGou/RegisterGou";
import MatrizComparative from "./Components/MatrizComparative/MatrizComparative";
import Licitation from "./Components/Licitation/Licitation";
import Proyectos from "./Components/Proyectos/Proyectos";
import CNSCoop from "./Components/CSNCoop";
import FinancePage from "./Components/FinancePage";
import UserReport from "./Components/Admin/UserReport/UserReport";
import LevelReport from "./Components/Admin/LevelReport/LevelReport";
import ScoreReport from "./Components/Admin/ScoreReport/ScoreReport";
import CreateUser from "./Components/CreateUser/CreateUser";
import Construction from "./Components/Construction/Construction";
import Layout from "./layout/Layout";
import RegisterMain from "./Components/RegisterMain/RegisterMain";
import AddProjects from "./Components/AddProjects/AddProjects";
import ForgotPass from "./Components/ForgotPass/ForgotPass";
const App = () => {
  return (
    <div>
      {/* <UserReport/> */}
      {/* <LevelReport/> */}
      {/* <ScoreReport/> */}
      
      <Router>
        {/* <Header /> */}

        <Routes>
          <Route path="/" element={<Construction />} />
          <Route path="/system/" element={<Layout><Home /></Layout>} />
          <Route path="/system/contact" element={<Layout><Contact /></Layout>} />
          <Route path="/system/proyectos" element={<Layout><Proyectos /></Layout>} />
          <Route path="/system/terms" element={<Layout><Terms /></Layout>} />
          <Route path="/system/matriz-comparative" element={<Layout><MatrizComparative /></Layout>} />
          <Route path="/system/licitation" element={<Layout><Licitation /></Layout>} />
         
          {/* MATRIZ DE PONDERACIONES in MatrizComparative needs overflow set to scroll */}

          {/* header footer need size adjustments */}

          <Route path="/system/register" element={<Layout><Register /></Layout>} />
          <Route path="/system/forgotpassword" element={<Layout><ForgotPass /></Layout>} />
          <Route path="/system/register-main" element={<Layout><RegisterMain /></Layout>} />
          <Route path="/system/register-doc" element={<Layout><RegisterDoc /></Layout>} />
          <Route path="/system/register-gourmet" element={<Layout><RegisterGou /></Layout>} />
          <Route path="/system/pro-register" element={<Layout><ProjectRegister /></Layout>} />
          <Route path="/system/contactinfo" element={<Layout><ContactInfo /></Layout>} />
          <Route path="/system/sup-register" element={<Layout><SupRegister /></Layout>} />
          <Route path="/system/login" element={<Layout><Login /></Layout>} />
          <Route path="/system/validation" element={<Layout><InternasContra /></Layout>} />

          {/* ************************************ */}

          <Route path="/system/cnscoop" element={<Layout><CNSCoop /></Layout>} />
          <Route path="/system/financepage" element={<Layout><FinancePage /></Layout>} />

          {/* admin routes */}
          <Route path="/admin/createuser" element={<Layout><CreateUser/></Layout>} />
          <Route path="/admin/addproject" element={<Layout><AddProjects/></Layout>} />
          <Route path="/admin/user-report" element={<Layout><UserReport /></Layout>} />
          <Route path="/admin/level-report" element={<Layout><LevelReport /></Layout>} />
          <Route path="/admin/score-report" element={<Layout><ScoreReport /></Layout>} />

        </Routes>

        {/* <Footer /> */}
      </Router>
    </div>
  );
};

export default App;
