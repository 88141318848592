import React, { useState, useEffect } from "react";
import {useLocation, useNavigate} from 'react-router-dom'
import styles from "./RegisterGou.module.css";
import Work2 from "../../Images/Work2.png";
import RegGoubtn from "../../Images/regGouBtn.png";
import FileUpIcn from "../../Images/fileupIcn.png";

const RegisterGou = () => {
  const location = useLocation()
  const navigate = useNavigate()
  
  const [formData, setFormData] = useState({
    project:"",
    amount: "",
    unit: "",
    charachteristics: "",
    specialSpecifications: "",
    unitPrice: "",
    totalPrice: "",
    warranties: "",
    medium: "",
    day: "",
    month: "",
    year: "",
    deliveryLocation: "",
    dateOfDelivery: new Date(),
    files: [],
  });

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    let projectId = queryParams.get('projectId');
    setFormData({project: projectId})
    
  }, [])
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { day, month, year, ...rest } = formData;

    // Convert month name to index (0-based)
    const monthIndex = months.indexOf(month);
    if (monthIndex === -1) {
      console.error("Invalid month selected");
      return;
    }
  
    const dateOfDelivery = new Date(year, monthIndex, day);
  
    const formDataToSubmit = {
      ...rest,
      dateOfDelivery,
    };
  
    const formDataToSend = new FormData();
    Object.entries(formDataToSubmit).forEach(([key, value]) => {
      if (key === "files") {
        value.forEach((file) => formDataToSend.append("files", file));
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const token = localStorage.token
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/bidding/create`, {
        method: "POST",
        body: formDataToSend,
        headers: {
          "x-auth-token": token
        }
      });
      const data = await response.json();
      if (data) {
        navigate("/system/matriz-comparative")
      } 
      console.log("Response:", data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];
  const startYear = 2024;
  const endYear = 2034;
  const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
  
    if (!file) {
      setErrorMessage("File is required.");
      return;
    }
  
    if (file.type !== "application/pdf") {
      setErrorMessage("Only PDF files are allowed.");
      return;
    }
  
    if (file.size > 150 * 1024) {
      setErrorMessage("File size must not exceed 150 KB.");
      return;
    }
  
    setErrorMessage(""); // Clear any previous error message
    setFileName(file.name);
  
    setFormData((prevState) => ({
      ...prevState,
      files: [...(prevState.files || []), file],
    }));
  };
  



  return (
    <div className={styles.registerGouMain}>
      <div className={styles.registerGouContainer}>
        <div className={styles.registerGouContainer2}>
          <div className={styles.homeSection2Head}>
            <div className={styles.workMainHeading}>
              <img src={Work2} alt="" className={styles.Work2Img} />
              Oferta de Licitación para Canapés Gourmet
            </div>
          </div>
          <div className={styles.registerGouSection2Main}>
            <div className={styles.registerInputMain2}>
              <div className={styles.inputRegisterLeft}>
                Cantidad:*
                <input
                  type="text"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
              </div>
              <div className={styles.inputRegisterRight}>
                Unidad:*
                <input
                  type="text"
                  name="unit"
                  value={formData.unit}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
              </div>
            </div>
            <div className={styles.regDocmainText}>Características:*</div>
            <textarea
              name="charachteristics"
              value={formData.charachteristics}
              onChange={handleChange}
              className={styles.registerTextarea}
            ></textarea>
            <div className={styles.regDocmainText}>Especificaciones Especiales:*</div>
            <textarea
              name="specialSpecifications"
              value={formData.specialSpecifications}
              onChange={handleChange}
              className={styles.registerTextarea2}
            ></textarea>
            <div className={styles.registerInputMain2}>
              <div className={styles.inputRegisterLeft}>
                Precio unitario: (Tipo de moneda: MXN o USD)*
                <input
                  type="text"
                  name="unitPrice"
                  value={formData.unitPrice}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
              </div>
              <div className={styles.inputRegisterRight}>
                Precio Total: (Antes de impuestos)*
                <input
                  type="text"
                  name="totalPrice"
                  value={formData.totalPrice}
                  onChange={handleChange}
                  className={styles.inputRegister2}
                />
              </div>
            </div>
            <div className={styles.regDocmainText}>Garantías:*</div>
            <textarea
              name="warranties"
              value={formData.warranties}
              onChange={handleChange}
              className={styles.registerTextarea3}
            ></textarea>
            <div className={styles.regDocmainText}>Soporte:*</div>
            <textarea
              name="medium"
              value={formData.medium}
              onChange={handleChange}
              className={styles.registerTextarea3}
            ></textarea>
            <div className={styles.regDocmainText}>Fecha de entrega del bien o servicio:*</div>
            <div className={styles.selecterMain}>
            <select
  name="day"
  value={formData.day}
  onChange={handleChange}
  className={styles.selecterInput}
>
  <option value="" disabled>Day:</option>
  {days.map((d) => (
    <option key={d} value={d}>{d}</option>
  ))}
</select>
<select
  name="month"
  value={formData.month}
  onChange={handleChange}
  className={styles.selecterInput2}
>
  <option value="" disabled>Month:</option>
  {months.map((m, index) => (
    <option key={index} value={m}>{m}</option>
  ))}
</select>
<select
  name="year"
  value={formData.year}
  onChange={handleChange}
  className={styles.selecterInput2}
>
  <option value="" disabled>Year:</option>
  {years.map((y) => (
    <option key={y} value={y}>{y}</option>
  ))}
</select>

            </div>
            <div className={styles.registerInputMain}>
              Lugar de entrega:*
              <input
                type="text"
                name="deliveryLocation"
                value={formData.deliveryLocation}
                onChange={handleChange}
                className={styles.inputRegister}
              />
            </div>
            <div className={styles.regDocmainText}>Cotización: Favor de integrar en documento pdf de máximo 150 kb:*</div>
                     {/* File Upload 1 */}
                     <div className={styles.fileUploadContainer}>
                      <input
                        type="file"
                        id="fileUpload1"
                        className={styles.regdocFileUp}
                        onChange={(e) => handleFileChange(e, 'quote')}
                        style={{ display: "none" }}
                        accept=".pdf"
                      />
                    <span className={styles.fileName}>{fileName}</span>
                    <label htmlFor="fileUpload1" className={styles.customFileUpload}>
                      <img src={FileUpIcn} alt="" className={styles.fileupIcn} />
                      ANEXAR
                    </label>
                    {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                  </div>

            <div className={styles.RegBtnIcnMain}>
              <button className={styles.RegBtnIcn2} type="submit" onClick={(e) => handleSubmit(e)}>
                <img src={RegGoubtn} alt="" className={styles.RegGoubtn} />
                <span>OFERTAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterGou;
