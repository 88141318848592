import {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { useLocation } from 'react-router-dom';

const Licitation = () => {
  const [project, setProject] = useState(null)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const items = ["Ambos", "Bienes", "Servicios"];
  const location = useLocation();

  const [activeButton, setActiveButton] = useState(null); // Track active button

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId); // Set the active button on click
  };

  let queryParams = new URLSearchParams(location.search);
  let id = queryParams.get('id');

  useEffect(() => {
    const fetchProject = async () => {

      queryParams = new URLSearchParams(location.search);

      id = queryParams.get('id');
      
      try {
          setLoading(true)
          console.log("Fetching projects data...");
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/project/${id}`, {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          });
          console.log("Data fetched:", response?.data?.data?.project);
          setProject(response?.data?.data?.project)
         

      } catch (error) {
        console.error("Error fetching data:", error.response ? error?.response?.data : error?.message);
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };
    fetchProject();
  }, [location.search]);
  console.log("aaaaaa", project)

  function formatDate(date) {
    console.log("formatting date", date);
    
    // Ensure the input date is a valid Date object
    const dateObject = new Date(date); // Convert string to Date object
    
    // Define the options for formatting
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    
    // Format the date using 'en-GB' locale
    return new Intl.DateTimeFormat('en-GB', options).format(dateObject);
  }
  return (
    <>
    {project && (<div className="relative flex flex-col bg-[url('./Assets/lowerBg.png')] bg-contain bg-center bg-no-repeat px-3">
        <div className="relative mb-[70px] max-h-[1000px]  w-full ">
          <div className="z-2 relative flex w-full flex-col items-center justify-center px-[4%] pt-[30px] md:justify-between">
            <div className="flex flex-col items-center">
              <div className="flex items-center gap-[15px] lg:gap-[40px]">
                <img
                  src={require("../../Assets/triangle.png")}
                  className="h-10 w-10 lg:h-16 lg:w-16"
                  alt=""
                />
                <div className="text-[25px] font-bold leading-[30px] text-[#71A850] lg:text-[36px] lg:leading-[47px]">
                  {/* Capanes Gourmet */}
                  {project?.title}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="z-2 ml-auto mr-auto flex w-full max-w-[1250px] flex-col items-center lg:items-start justify-between gap-[20px] lg:flex-row xl:gap-[0px]"
          style={{ zIndex: "20px" }}
        >
          <div className="max-w-[692px]">
            <div>
              <img src={`${process.env.REACT_APP_BASE_URL}/${project?.files[0]}`} alt="" className="w-full" />
            </div>

            <div className="mt-[20px] flex items-center justify-center gap-[20px] md:flex-row md:justify-between max-md:gap-[0px]">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${project?.files[0]}`}
                alt=""
                className="w-full max-w-[220px] h-[100px] object-cover"
              />
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${project?.files[1]}`}
                alt=""
                className="w-full max-w-[220px] h-[100px] object-cover"
              />
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${project?.files[2]}`}
                alt=""
                className="w-full max-w-[220px] h-[100px] object-cover"
              />
            </div>
          </div>

          <div className="box w-full max-w-[320px] rounded-[12px] border-[1px] bg-white border-[#ECE8E8] py-[20px] text-center">
            <div className="mx-auto max-w-[200px] text-start text-[13px] font-bold leading-[15px]">
              Tipo de requerimiento: <span className="font-normal">{project?.typeOfRequirement}</span>
            </div>
            <div className="mx-auto mt-[10px] max-w-[200px] text-start text-[13px] font-bold leading-[15px]">
              Cantidad: <span className="font-normal">{project?.budget}</span>
            </div>

            <div className="mx-auto mt-[20px] max-w-[242px] pl-[18px] text-start">
              {project?.description}
            </div>

            <div className="mx-auto mt-[20px] max-w-[231px] text-start">
              <ul className="flex list-disc flex-col gap-[10px] pl-5 text-[12px] font-bold">
                <li>Fecha de apertura: {formatDate(project?.openingDate)}</li>
                <li>Fecha de entrega: {formatDate(project?.dateOfDelivery)}</li>
                <li>Fecha límite para entregar propuestas: {formatDate(project?.deadline)}</li>
                <li>Fecha de cierre (Toma de decisión): {formatDate(project?.closingDate)}</li>
              </ul>
            </div>

            <div className="mt-[10px] flex items-center justify-center">
              <img src={require("./Group 348.png")} alt="" />
            </div>
<div className=" flex justify-center items-center">
<Link
              to={`/system/register-gourmet?projectId=${id}`}
              className="mb-10 mt-4 flex items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 w-[60%] text-white"
            >
              <img src={require("../../Assets/bidIcon.png")} className="w-[50px] " alt="" />
              <span className="font-russo text-lg">OFERTAR</span>
            </Link>
</div>
          

            {/* <div className="mx-auto mt-[20px] flex max-w-[269px] items-center justify-between rounded-[8px] bg-[#D9D9D9D1] p-[10px]">
              <div>
                <div className="fontClass text-[22px] font-bold leading-[25px] text-black">
                  25
                </div>
                <div className="fontClass text-[12px] font-medium leading-[14px] text-[#4A4F54]">
                  Días
                </div>
              </div>
              <div>
                <div className="fontClass text-[22px] font-bold leading-[25px] text-black">
                  25
                </div>
                <div className="fontClass text-[12px] font-medium leading-[14px] text-[#4A4F54]">
                  Días
                </div>
              </div>
              <div>
                <div className="fontClass text-[22px] font-bold leading-[25px] text-black">
                  25
                </div>
                <div className="fontClass text-[12px] font-medium leading-[14px] text-[#4A4F54]">
                  Días
                </div>
              </div>
              <div>
                <div className="fontClass text-[22px] font-bold leading-[25px] text-black">
                  25
                </div>
                <div className="fontClass text-[12px] font-medium leading-[14px] text-[#4A4F54]">
                  Días
                </div>
              </div>
            </div> */}

            <div className="w-fit ml-auto mr-auto rounded-md bg-slate-300 px-2 py-1 opacity-90">
              <FlipClockCountdown
                to={new Date(project?.deadline)}
                labels={["DIAS", "HORAS", "MINUTOS", "SEGUNDOS"]}
                labelStyle={{
                  fontSize: 10,
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
                digitBlockStyle={{
                  width: 24,
                  height: 36,
                  fontSize: 20,
                  color: "black",
                  backgroundColor: "rgba(203, 213, 225, 0.95)",
                }}
                dividerStyle={{
                  color: "red",
                  height: 0,
                }}
                separatorStyle={{ color: "transparent", size: "0px" }}
                duration={1}
              >
                {/* component rendered when timer finishes */}
                <div className="flex items-center justify-center px-2 py-1">
                  <h3 className="text-center">Licitación terminada.</h3>
                </div>
              </FlipClockCountdown>
            </div>
          </div>
        </div>
        <div className="mt-[40px] flex w-full justify-around flex-wrap items-center gap-[10px] px-[30px] lg:justify-around">
      <Link 
      to="/system/matriz-comparative">
      <button
        className={`btnBorder flex h-[55px] w-[150px] font-Russo items-center justify-center gap-[4px] rounded-[9px] text-[16px] leading-[18px] text-white lg:w-[247px] lg:text-[20px] lg:leading-[24px] ${
          activeButton === 1 ? 'bg-[#025624]' : 'bg-[#585857]' // Active or inactive background
        } hover:bg-[#71A850]`} // Hover state
        onClick={() => handleButtonClick(1)} // Set active on click
      >
        <img
          src={require("../../Assets/btn1Image.png")}
          className="h-[20px] w-[25px] lg:h-[33px] lg:w-[41px]"
          alt=""
        />
        <div className="w-[95px] lg:w-[150px] font-russo">TABLA DE RESULTADOS</div>
      </button>
      </Link>
      <Link 
      to="/system/matriz-comparative">
      <button 
        className={`btnBorder flex h-[55px] w-[170px] items-center justify-center gap-[4px] rounded-[9px] text-[16px] leading-[18px] text-white lg:w-[247px] lg:text-[20px] lg:leading-[24px] ${
          activeButton === 2 ? 'bg-[#025624]' : 'bg-[#585857]' // Active or inactive background
        } hover:bg-[#71A850]`} // Hover state
        onClick={() => handleButtonClick(2)} // Set active on click
      >
        <img
          src={require("../../Assets/btn2Image.png")}
          className="h-[20px] w-[20px] lg:h-[30px] lg:w-[30px]"
          alt=""
        />
        <div className="w-[95px] lg:w-[150px] font-russo">MATRIZ COMPARATIVA</div>
      </button>
      </Link>

      <Link 
      to="/system/matriz-comparative">
      <button
        className={`btnBorder flex h-[55px] w-[180px] items-center justify-center gap-[4px] rounded-[9px] text-[16px] leading-[18px] text-white lg:w-[247px] lg:gap-[7px] lg:text-[20px] lg:leading-[24px] ${
          activeButton === 3 ? 'bg-[#025624]' : 'bg-[#585857]' // Active or inactive background
        } hover:bg-[#71A850]`} // Hover state
        onClick={() => handleButtonClick(3)} // Set active on click
      >
        <img
          src={require("../../Assets/btn3Image.png")}
          className="h-[20px] w-[20px] lg:h-[30px] lg:w-[30px]"
          alt=""
        />
        <div className="w-[120px] lg:w-[150px] font-russo">MATRIZ DE PONDERACIONES</div>
      </button>
      </Link>
    </div>

        <div className="box mx-auto mb-[40px] mt-[20px] max-w-[1250px] rounded-[12px] border-[1px] border-[#C7C7CD] p-[50px] px-[20px]">
          <div className="mt-[30px] flex items-center justify-center text-center text-[32px] font-bold leading-[42.2px] font-roboto text-[rgba(74,79,84,1)]">
            <img src={require("./Group 278.png")} alt="" />
            Características
          </div>
          <ul className="mx-auto mt-[40px] max-w-[1250px] list-disc pl-5">
            {/* <li>
              Ed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </li>
            <li>
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
              aut fugit, sed quia consequuntur magni dolores eos qui ratione
              voluptatem sequi nesciunt.
            </li>
            <li>
              Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
              consectetur, adipisci velit, sed quia non numquam eius modi
              tempora incidunt ut labore et dolore magnam aliquam quaerat
              voluptatem.
            </li>
            <li>
              Ut enim ad minima veniam, quis nostrum exercitationem ullam
              corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
              consequatur?
            </li>
            <li>
              Quis autem vel eum iure reprehenderit qui in ea voluptate velit
              esse quam nihil molestiae consequatur, vel illum qui dolorem eum
              fugiat quo voluptas nulla pariatur?
            </li> */}
            {project?.features}
          </ul>
          <div className="mt-[30px] flex items-center justify-center text-center text-[32px] font-bold leading-[42.2px] font-roboto text-[rgba(74,79,84,1)]">
            <img src={require("./Group 278.png")} alt="" />
            Especificaciones Especiales
          </div>
          <div className="mx-auto max-w-[1250px] text-center">
            {project?.specialSpecifications}
          </div>

          <div className="mx-auto mt-[20px] flex w-full max-w-[1250px] items-center justify-center bg-[#D9D9D9D1] pt-[10px] text-center text-[24px] font-bold leading-[31.65px] font-roboto text-[rgba(74,79,84,1)]">
            <img src={require("./Group 278.png")} alt="" />
            Historial de subastas
          </div>
          <ul className="mx-auto max-w-[1250px] list-disc pl-5 text-start">
            <li>
              06, Julio, 2024: Empresa Bocadillos y Canapés realiza una oferta
              de licitación.
            </li>
            <li>03, Julio, 2024: Inició el proceso de aceptar licitaciones.</li>
          </ul>

          <div className="mx-auto mt-[20px] flex w-full max-w-[1250px] items-center justify-center bg-[#D9D9D9D1] pt-[10px] text-center text-[24px] font-bold leading-[31.65px] font-roboto text-[rgba(74,79,84,1)]">
            <img src={require("./Group 278.png")} alt="" />
            Preguntas sobre licitación
          </div>
          <ul className="mx-auto max-w-[1250px] list-disc pl-5 text-start">
            <li>
              06, Julio, 2024: Empresa Bocadillos y Canapés realiza una oferta
              de licitación.
            </li>
            <li>03, Julio, 2024: Inició el proceso de aceptar licitaciones.</li>
          </ul>

          <div className="mt-[20px] flex items-center justify-center">
            <img src={require("./PREGUNTAR-OFF.png")} width={130} className=" cursor-pointer" alt="" />
          </div>
        </div>
      </div>)}
      {loading && "Loading..."}
    </>
  );
};

export default Licitation;
