import { useState, useEffect } from "react";
import axios from "axios"; // Make sure to install axios if not installed yet
import Card from "./Card";
import { PiGridFourLight, PiList } from "react-icons/pi";

const Proyectos = () => {
  const [view, setView] = useState("grid");
  const [projects, setProjects] = useState([]); // Ensuring it's an array by default
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy,setSortBy]=useState(null);
  const [additionalFilter, setAdditionalFilter] = useState("");


  const items = ["Ambos", "Bienes", "Servicios"];
  const items2 = ["Proyectos Generales", "Proyectos de T.I.", "Proyectos de Construcción"];
  // const items = ["saffs", "safsfa", "test","sadasd"];

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        console.log("Fetching projects data...");
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/project/all`, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });
        console.log("Data fetched:", response?.data?.data?.projects);
        setProjects(response?.data?.data?.projects || []);
      } catch (error) {
        console.error("Error fetching data:", error.response ? error?.response?.data : error?.message);
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Filter projects based on the search term AND description(type of project)
  const filteredProjects = projects
  .filter((project) => 
    project?.title?.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (sortBy ? project?.typeOfRequirement === sortBy : true)
  )
  .filter((project) => {
    if (additionalFilter === "recent") {
      return true; // Sorting will handle this case
    }
    if (additionalFilter === "oldest") {
      return true; // Sorting will handle this case
    }
    if (additionalFilter === "active") {
      return project?.status === "active";
    }
    if (additionalFilter === "closed") {
      return project?.status === "closed";
    }
    return true;
  })
  .sort((a, b) => {
    if (additionalFilter === "recent") {
      return new Date(b?.createdAt) - new Date(a?.createdAt);
    }
    if (additionalFilter === "oldest") {
      return new Date(a?.createdAt) - new Date(b?.createdAt);
    }
    return 0; // No sorting for active or closed
  });


  return (
    <div className="bg-[url('./Images/workBg.png')] bg-cover bg-center bg-no-repeat">
      <div className="flex w-full flex-col px-2 pb-32 pt-12 sm:py-32">
        <div className="mx-auto mb-16 flex items-center justify-center gap-4 sm:gap-10">
          <img
            src={require("../../Assets/triangle.png")}
            className="h-9 w-9 sm:h-16 sm:w-16"
            alt=""
          />
          <div className="text-md font-bold font-roboto leading-[30px] text-[#71A850] sm:text-2xl sm:leading-[47px] md:text-4xl">
            Proyectos de Licitaciones
          </div>
        </div>

        <div className="mx-auto flex w-full max-w-[1440px] flex-col items-center justify-between gap-12 pl-2 pr-4 xl:flex-row xl:items-start xl:gap-2">
          <div className="flex flex-col items-stretch gap-4">
            <div className="flex h-14 w-72 items-center justify-center rounded-lg bg-[#D9D9D9] px-3">
              <input
                type="text"
                placeholder="Buscar Proyectos..."
                className="w-full border-none bg-transparent outline-none"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img src={require("../../Images/Intra/search.png")} alt="search" />
            </div>
            <div className="rounded-lg bg-[#D9D9D9] p-4">
              <label className="text-[22px] mb-2 font-bold leading-[26px] text-[#828282]">
                Proyectos de:
              </label>
              <ul className="list-disc pl-7 flex items-start flex-col gap-3">
                {items.map((item, index) => (
                  <button onClick={()=>setSortBy(sortBy=>sortBy===item?null:item)}><li
                  key={index}
                  className={`fontClass text-[16px] font-bold leading-[18px]  ${sortBy===item?"text-zinc-900":"text-zinc-500"}`}
                >
                  {item}
                </li></button>
                ))}
              </ul>
            </div>
            <div className="rounded-lg bg-[#D9D9D9] p-4">
              <label className="text-[22px] mb-2 font-bold leading-[26px] text-[#828282]">
              Tipos de Proyectos:
              </label>
              <ul className="list-disc pl-7 flex items-start flex-col gap-3">
                {items2.map((item, index) => (
                  <button><li
                  key={index}
                  className={`fontClass text-[16px] font-bold leading-[18px]  ${sortBy===item?"text-zinc-900":"text-zinc-500"}`}
                >
                  {item}
                </li></button>
                ))}
              </ul>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between px-2 pb-2">
              <p className="text-xs font-medium text-zinc-500">
              Mostrando  {`${filteredProjects.length} resultado${filteredProjects.length===1?"":"s"}`}
              </p>
              <div className="flex gap-4">
              <select
  name="sortBy"
  className="rounded-lg bg-slate-100 px-2 py-1"
  value={additionalFilter}
  onChange={(e) => setAdditionalFilter(e.target.value)}
>
  <option value="">Filtros adicionales:</option>
  <option value="recent">De más reciente a más antiguo.</option>
  <option value="oldest">De más antiguo a más reciente.</option>
  <option value="active">Licitaciones activas.</option>
  <option value="closed">Proyectos cerrados.</option>
</select>

                {/* <div className="flex gap-1">
                  <button
                    className={`${view === "grid" ? "bg-slate-800" : ""} rounded-lg bg-slate-100 p-0.5`}
                    onClick={() => setView("grid")}
                  >
                    <PiGridFourLight className={`${view === "grid" ? "fill-white" : ""} h-7 w-7`} />
                  </button>
                  <button
                    className={`${view === "list" ? "bg-slate-800" : ""} rounded-lg bg-slate-100 p-0.5`}
                    onClick={() => setView("list")}
                  >
                    <PiList className={`${view === "list" ? "fill-white" : ""} h-7 w-7`} />
                  </button>
                </div> */}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-3 gap-y-8 sm:grid-cols-2 sm:gap-x-4 lg:grid-cols-3 xl:gap-8 min-h-screen">
              {filteredProjects.length > 0 ? (
                filteredProjects.map((project) => (
                  <Card
                    key={project?._id}
                    project={{
                      _id: project?._id,
                      img: project?.files[0],
                      name: project?.title,
                      text: project?.typeOfRequirement,
                      deadline: project?.deadline,
                    }}
                  />
                ))
              ) : (
                <p>No se encontró ningún proyecto con esas características.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proyectos;
