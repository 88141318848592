import React from "react";
import styles from "./Home.module.css";
import Home1 from "../../Images/home1.png";
import Home2 from "../../Images/home2.png";
import Home3 from "../../Images/home3.png";
import Home4 from "../../Images/home4.png";
import Home5 from "../../Images/home5.png";
import homebtn2Icn from "../../Images/home2btnicn.png";
import Work2 from "../../Images/Work2.png";
import Work3 from "../../Images/Work3.png";
import Work4 from "../../Images/work4.png";
import Work5 from "../../Images/work5.png";
import Logo1 from "../../Images/1.png";
import Logo2 from "../../Images/2.png";
import Logo3 from "../../Images/3.png";
import Logo4 from "../../Images/4.png";
import Logo5 from "../../Images/5.png";
import Logo6 from "../../Images/6.png";
import Logo7 from "../../Images/7.png";
import Logo8 from "../../Images/8.png";
import Logo9 from "../../Images/9.png";
import Logo10 from "../../Images/10.png";
import Logo11 from "../../Images/11.png";
import Logo12 from "../../Images/12.png";
import Sec3Img from "../../Images/sec3Img.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div>
      <div className={styles.homeMain}>
        <Slider
          infinite={true}
          speed={1000}
          slidesToShow={1}
          slidesToScroll={1}
          autoplaySpeed={5000}
          autoplay={true}
          pauseOnHover={false}
        >
          <div>
            <div className={styles.homeContainer}>
              <div className={styles.homeHeadingSection1Main}>
                <img src={Home1} alt="" className={styles.homeImg} />
                <div className={styles.homeMainHeading}>
                  ¡Somos la solución a cada momento en tu vida!
                </div>
                <img src={Home2} alt="" className={styles.home2Img} />
              </div>
              <div className={styles.homeHeadingSection2Main}>
                <img src={Home3} alt="" className={styles.home3Img} />
              </div>
            </div>
          </div>

          <div>
            <div className={styles.homeContainer2}>
              <div className={styles.homeHeadingSection3Main}>
                <img src={Home2} alt="" className={styles.homeImg} />
                <div className={styles.hometextBtnMain}>
                  <div className={styles.homeMainHeading}>
                    ¡Regístrate como proveedor y participa en nuestros
                    proyectos!
                  </div>
                  <Link
                    to="/system/register-main"
                    className="mb-10 mt-4 flex items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 hover:bg-green-700 px-3 py-2 text-white"
                  >
                    <img src={require("../../Assets/buttonIcon.png")} alt="" />
                    <span className="font-russo text-xl">VER MAS</span>
                  </Link>
                </div>

                <img src={Home4} alt="" className={styles.home4Img} />
              </div>
              <div className={styles.homeHeadingSection4Main}>
                <img src={Home5} alt="" className={styles.home3Img} />
              </div>
            </div>
          </div>
        </Slider>

        {/* Section 2 */}

        <div className={styles.homeSection2Main}>
          <div className={styles.homeSection2Head}>
            {/* <img src={Work} alt="" className={styles.WorkImg} /> */}
            <div className={styles.workMainHeading}>
              <img src={Work2} alt="" className={styles.Work2Img} />
              ¿Cómo funciona?
            </div>
            {/* <img src={Work3} alt="" className={styles.Work3Img} /> */}
          </div>
          <div className={styles.workSectionMain}>
            <div className={styles.workSection}>
              <img src={Logo1} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Registro</div>
              <div className={styles.workSectionText}>
                Registra tu cuenta de proveedor.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo2} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Carga</div>
              <div className={styles.workSectionText}>
                Carga la información y los documentos requeridos para darte de
                alta como proveedor.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo3} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Aprobación</div>
              <div className={styles.workSectionText}>
                Espera tu aprobación de alta como proveedor.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo4} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Descubre</div>
              <div className={styles.workSectionText}>
                Revisa los proyectos que tenemos publicados.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo5} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Selecciona</div>
              <div className={styles.workSectionText}>
                Selecciona el proyecto o proyectos en los que puedas participar.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo6} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Participa</div>
              <div className={styles.workSectionText}>
                Participa realizando tu propuesta incluyendo presupuesto,
                plazos, entregables, etc.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo7} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Evaluación</div>
              <div className={styles.workSectionText}>
                Evaluación de las propuestas de los distintos proveedores.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo8} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Ganador</div>
              <div className={styles.workSectionText}>
                En base al análisis del evaluador se determinará el ganador y se
                enviará la respectiva notificación vía correo al proveedor
                seleccionado.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo9} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Contratos</div>
              <div className={styles.workSectionText}>
                Al dar clic en el correo te llevaremos a un portal donde deberás
                de proveernos de documentación adicional y el borrador de
                contratos para ser revisado por el área jurídica antes de
                iniciar la relación comercial.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo10} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Lanzamiento</div>
              <div className={styles.workSectionText}>
                Si tuviste el mérito de ser seleccionado como el mejor proveedor
                para el proyecto, una vez firmado el contrato por ambas partes,
                deberás comenzar a trabajar en el proyecto asignado.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo11} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Desarrollo</div>
              <div className={styles.workSectionText}>
                Continuar y terminar con el proyecto de acuerdo con los
                estipulado en el contrato.
              </div>
            </div>
            <div className={styles.workSection}>
              <img src={Logo12} alt="" className={styles.logo1} />
              <div className={styles.workSectionHeading}>Pago</div>
              <div className={styles.workSectionText}>
                Obtén el pago acordado con lo estipulado en el contrato en base
                a la verificación final de término del proyecto.
              </div>
            </div>
          </div>
          <div className={styles.workSection2Main}>
            {/* <img src={Work4} alt="" className={styles.Work4Img} />
        <img src={Work5} alt="" className={styles.Work5Img} /> */}
          </div>
        </div>

        {/* Section 3  */}

        <div className={styles.section3Main}>
          <div className={styles.section3Container}>
            <div className={styles.section3Heading}>
              <img src={Sec3Img} alt="" className={styles.sec3Img} />
              Transparencia
            </div>
            <div className={styles.section3Text}>
              La cooperativa se compromete a ser transparente en el proceso de
              selección.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
