import { useState } from "react"
import { FaRegEyeSlash,FaRegEye } from "react-icons/fa";

function CreateUser() {

 const [activeTab,setActiveTab]= useState(0)
 const [showPassword,setShowPassword]=useState(false)
 const [showConfirmPassword,setShowConfirmPassword]=useState(false)

const tabs=[
  {name:"Creacion de Usuarios",img:require("../../Assets/tab1.png")},
  {name:"Administracion de Usuarios",img:require("../../Assets/tab1.png")},
  {name:"Añadir  Proyectos",img:require("../../Assets/tab1.png")},
  {name:"Envio de Correos",img:require("../../Assets/tab1.png")},
  {name:"Auditoria de Usos",img:require("../../Assets/tab1.png")},
  {name:"Reportes",img:require("../../Assets/tab1.png")},
]

  return (
    <div className="grid md:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_3fr] xl:grid-cols-[1fr_4fr]">
      
      <ul className="h-full min-h-screen border-t border-solid border-green-600 bg-[#71A850]">
        {tabs.map((tab,i)=><li className={`${activeTab===i?"bg-[#025624]":"bg-[#71A850]"} flex flex-shrink-0 items-center gap-4 cursor-pointer px-4 py-4 border-b border-solid border-green-600`} onClick={()=>setActiveTab(i)}>
          <img src={tab.img} alt="" />
          <p className="text-white font-semibold text-lg">{tab.name}</p>
        </li>)}
      </ul>

      <div className="bg-[url('./Assets/Fondo-sistema.png')] bg-cover bg-no-repeat flex flex-col items-center">
        <div className="flex items-center mt-12 sm:mt-36 mb-12 gap-4 lg:gap-10">
          <img src={require("../../Assets/triangleDark.png")} className="h-10 w-10 lg:h-16 lg:w-16" alt=""/>
          <div className="text-2xl font-bold leading-[30px] text-[#025624] lg:text-[36px] lg:leading-[47px]">
            {tabs[activeTab].name}
          </div>
        </div>

        {activeTab===0 && <form className="flex flex-col gap-8 w-72 sm:w-96">
          <div className="flex flex-col gap-3">
            <label for="tipo-de-usuario" className="text-[#025624] font-semibold text-xl">Tipo de usuario:*</label>
            <select name="user" id="tipo-de-usuario" className="bg-[#D9D9D9] px-4 py-3 rounded-sm text-xl">
             <option value="">--Please choose an option--</option>
             <option value="administrador">Administrador</option>
             <option value="votante">Votante</option>
             <option value="auditor">Auditor</option>
             <option value="adquisiciones">Adquisiciones</option>
             <option value="contraloria">Contraloria</option>
             <option value="creador">Creador Le Licitaciones</option>
             </select>
          </div>

          <div className="flex flex-col gap-3">
            <label for="nombre" className="text-[#025624] font-semibold text-xl">Nombre(s):*</label>
            <input type="tel" name="number" id="nombre" className="bg-[#D9D9D9] px-4 py-3 rounded-sm text-xl" /> 
          </div>

          <div className="flex flex-col gap-3">
            <label for="electronico" className="text-[#025624] font-semibold text-xl">Correo Electronico:*</label>
            <input type="email" name="email" id="electronico" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="bg-[#D9D9D9] px-4 py-3 rounded-sm text-xl" /> 
          </div>

          <div className="flex flex-col gap-3">
            <label for="contrasena" className="text-[#025624] font-semibold text-xl">Contrasena:*</label>
            <div className="bg-[#D9D9D9] px-4 py-3 rounded-sm text-xl flex items-center justify-between">
              <input className="w-full bg-[#D9D9D9]" type={showPassword?"text":"password"} name="password" id="contrasena"  />
              <span className="cursor-pointer" onClick={()=>setShowPassword((open)=>!open)}>
                {showPassword?<FaRegEyeSlash/>:<FaRegEye/>}</span>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <label for="confirmar-contrasena" className="text-[#025624] font-semibold text-xl">Confirmar Contrasena:*</label>
            <div className="bg-[#D9D9D9] px-4 py-3 rounded-sm text-xl flex items-center justify-between">
              <input className="w-full bg-[#D9D9D9]" type={showConfirmPassword?"text":"password"} name="password" id="confirmar-contrasena"  />
              <span className="cursor-pointer" onClick={()=>setShowConfirmPassword((open)=>!open)}>
                {showConfirmPassword?<FaRegEyeSlash/>:<FaRegEye/>}</span>
            </div>
          </div>

          <button
          type="submit"
          className="mb-16 flex self-center items-center justify-center gap-2 rounded-lg border-4 border-solid border-white bg-green-800 px-3 py-2 text-white hover:bg-green-700"
        >
          <img src={require("../../Assets/buttonIcon.png")} alt="" />
          <span className="font-russo text-lg">GUARDAR</span>
        </button>

        </form>}
        
      </div>
    </div>
  )
}

export default CreateUser
